<template>
  <v-form v-model="validForm" ref="contactForm">
    <v-container>

      <v-row>
        <v-col cols="12" sm="6">
          <MsisdnInput v-model="customer.msisdn" />
        </v-col>
        <v-col cols="12" sm="6" align="center">
          <PartnerIdentifierGenerator v-model="customer.partnerIdentifier" />
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12" sm="6">
          <NameInput v-model="customer.firstName" :label="`${$t('First Name')}`" />
        </v-col>

        <v-col cols="12" sm="6">
          <NameInput v-model="customer.surname" :label="`${$t('Surname')}`" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <IdentityInput v-model="customer.identity" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <TextInput v-model="customer.subsidyRegNo" :label="`${$t('Subsidy Registration Number')}`" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <BirthdateInput v-model="customer.birthdate" required :label="`${$t('Date of birth')}`" />
        </v-col>
        <v-col cols="12" sm="6">
          <BirthdateInput v-model="customer.memberSince" required dropLists :label="`${$t('Member since')}`" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <NameInput v-model="customer.nextOfKin" :label="`${$t('Next of Kin Name')}`" />
        </v-col>

        <v-col cols="12" sm="6">
          <MsisdnInput v-model="customer.nextOfKinMsisdn" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <NameInput v-model="customer.relationship" :label="`${$t('Relationship to farmer')}`" />
        </v-col>

      </v-row>

      <v-row >
        <RegionPicker v-model="customer.regions" />
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <GenderInput v-model="customer.gender" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <TextInput v-model="customer.noFields" :label="`${$t('Number of fields')}`" />
        </v-col>
        <v-col cols="12" sm="6">
          <LandSizeInput :label="`${$t('Total Field size')}`" v-model="customer.fieldSize" />
        </v-col>
      </v-row>

      <v-row v-if="depotName">
        <v-col cols="12">
          <v-checkbox v-model="customer.addToDepot" :label="`${$t('addToDepot')} (${depotName})?`"></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <div class="text-left">
              <v-btn @click="$emit('clearInputs')" class="d-none d-sm-inline">{{$t('Clear')}}</v-btn>
            </div>
            <div class="text-right">
              <v-btn @click="$emit('submitAndEdit')" color="primary" :disabled="!validForm" class="mr-2">{{$t('SaveAndViewDetail')}}</v-btn>
              <v-btn @click="$emit('submitAndNew')" color="primary" :disabled="!validForm">{{$t('SaveAndAddNew')}}</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      

    </v-container>
  </v-form>
</template>


<script>
  import RegionPicker from '@/components/RegionPicker';
  import MsisdnInput from '@/components/MsisdnInput';
  import NameInput from '@/components/NameInput';
  import TextInput from '@/components/TextInput';
  import LandSizeInput from '@/components/LandSizeInput';
  import IdentityInput from '@/components/IdentityInput';
  import GenderInput from '@/components/GenderInput';
  import PartnerIdentifierGenerator from '@/components/PartnerIdentifierGenerator';
  import BirthdateInput from "@/components/BirthdateInput";

  export default {

    components: { RegionPicker, MsisdnInput, NameInput, TextInput, LandSizeInput, IdentityInput, BirthdateInput, GenderInput, PartnerIdentifierGenerator },

    props: ['customer'],

    data: () => ({
      validForm: false,
      identityValid: true,
    }),

    computed: {
      depotId() { return this.$store.getters.settings.fieldAgentDepotId; },
      depotName() { return ((this.$store.getters.settings.depots.filter(e => e.id==this.depotId )[0]) || {}).name },
    },
    methods: {
      resetForm() {
        this.$refs.contactForm.reset()
      }
    },

    watch: { 
      customer: {
        immediate: true,
        handler(to) {
          if (to) {
            to.addToDepot = ( this.$store.getters.settings.fieldAgentDepotId ? true : false );
          }
        },
      },
    },


  }
</script>


<i18n>
{
  "en": {
    "clear": "Clear",
    "save": "Save & Add",
    "addToDepot": "Add farmer to your your depot",
    "SaveAndView": "Save & View Detail",
    "SaveAndAdd": "Save & Add",
    "Clear": "Clear",
    "Relationship to farmer": "Relationship to farmer",
    "Next of Kin Name": "Next of Kin Name",
    "Number of fields": "Number of fields",
    "Total Field size": "Total Field size",
    "Member since": "Member since",
    "Date of birth": "Date of birth",
    "First Name": "First Name",
    "Surname": "Surname"
  },
  "es": {
    "clear": "Clara",
    "save": "Guardar y agregar",
    "addToDepot": "Añadir agricultor a tu tu molino",
    "SaveAndView": "Guardar y ver detalles",
    "SaveAndAdd": "Salvar y añadir (Otro Contacto)",
    "Clear": "Reiniciar"
  },
  "sw": {
    "clear": "Futa",
    "save": "Sajili",
    "addToDepot": "Ongeza mkulima kwenye shirika lako la ununuzi",
    "SaveAndView": "Hifadhi &Onyesha Mafafanuzi",
    "SaveAndAdd": "Hifadhi na sajili mwingine",
    "Clear": "Futa",
    "Relationship to farmer": "Uhusiano na mkulima",
    "Next of Kin Name": "Jina la Jamaa wa karibu",
    "Number of fields": "Idadi ya mashamba",
    "Total Field size": "Jumla ya ukubwa wa uwanja",
    "Member since": "Mwanachama tangu",
    "Date of birth": "Tarehe ya kuzaliwa",
    "First Name": "Jina la kwanza",
    "Surname": "Jina la ukoo"
  }
}
</i18n>
